import styles from './global-infra.module.scss';

export const SectionGlobalInfra = () => {
	return (
		<div className={styles.base}>
			<div className={styles.container}>
				<div className={styles.advatages}>
					{[
						[
							'Speed is critical to customers — and SEO',
							"With AWS's globally backed content delivery network in 450+ Points of Presence and 13 regional edge caches spanning 90+ cities across 48 countries, Embargo delivers to the edge the best performance for your end users.",
						],
						[
							'Continous Stability via Deployment Trains',
							'Live at the cutting edge with newly shipped features, or hold back a few versions behind to maximize stability. Preview upcoming deployments on staging and prod on your internal devs before your customers. The choice is yours.',
						],
						[
							'Coming Soon — Performance Monitoring',
							'Comunicate peace of mind to key stakeholders with upcoming continous production monitoring and benchmarking tools.',
						],
					].map(([title, desc]) => (
						<div
							key={title}
							className={styles.advantage}>
							<div className={styles.title}>{title}</div>
							<div className={styles.desc}>{desc}</div>
						</div>
					))}
				</div>
				{/* <Img src={globe}/> */}
			</div>
		</div>
	);
};
