import { useEffect, useState } from 'react';
import styles from './countdown-timer.module.scss';

const MS_PER_SECOND = 1000;

const SECONDS_PER_MINUTE = 60;
const SECONDS_PER_HOUR = SECONDS_PER_MINUTE * 60;
const SECONDS_PER_DAY = SECONDS_PER_HOUR * 24;

const msToDuration = (targetDate: Date) => {
	const msDiff = +targetDate - Date.now();
	let delta = msDiff / MS_PER_SECOND;

	// calculate (and subtract) whole days
	const days = Math.max(0, Math.floor(delta / SECONDS_PER_DAY));
	delta -= days * SECONDS_PER_DAY;

	// calculate (and subtract) whole hours
	const hours = Math.max(0, Math.floor(delta / SECONDS_PER_HOUR) % 24);
	delta -= hours * SECONDS_PER_HOUR;

	const minutes = Math.max(
		Math.floor(delta / SECONDS_PER_MINUTE) % SECONDS_PER_MINUTE,
	);
	delta -= minutes * SECONDS_PER_MINUTE;

	const seconds = Math.max(0, Math.floor(delta % SECONDS_PER_MINUTE));

	return {
		days,
		hours,
		minutes,
		seconds,
	};
};

type CountdownCardProp = {
	unit: string;
	number: number;
};
const CountdownCard = ({ unit, number }: CountdownCardProp) => {
	return (
		<div className={styles.card}>
			<div className={styles.cardBg}>
				<div className={styles.cardNumber}>
					{String(number).padStart(2, '0')}
				</div>
			</div>
			<div className={styles.cardLabel}>{unit}</div>
		</div>
	);
};

type CountdownTimerProps = {
	date: Date;
};
export const CountdownTimer = ({ date }: CountdownTimerProps) => {
	const [timeGroups, setTimeGroups] = useState(msToDuration(date));

	useEffect(() => {
		const interval = setInterval(() => {
			setTimeGroups(() => msToDuration(date));
		}, 1000);
		return () => clearInterval(interval);
	}, []);

	return (
		<div className={styles.countdown}>
			<CountdownCard
				number={timeGroups.days}
				unit='Days'
			/>
			<CountdownCard
				number={timeGroups.hours}
				unit='Hours'
			/>
			<CountdownCard
				number={timeGroups.minutes}
				unit='Mins'
			/>
			<CountdownCard
				number={timeGroups.seconds}
				unit='Secs'
			/>
		</div>
	);
};
