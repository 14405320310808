import { SectionGlobalInfra } from '@root/components/sections/custom-designs/global-infra/';
import { Hero0 } from '@root/components/sections/heros/hero0/';
import { MetricsPanel } from '@root/components/sections/metrics-panel/';
import { Footer } from '@root/components/ui/layout/footers/footer-main/';
import designGuidelines from '@root/images/illustrations/guidelines.png';
import designTags from '@root/images/illustrations/tagging.png';
import designVisualTagger from '@root/images/illustrations/visual-tagger.png';
import Image from 'next/image';
import styles from './index.module.scss';
// import { SectionGlobe } from '@root/components/sections/custom-designs/globe/'
import { Headers } from '@root/components/app/headers';
import { SectionHoverEffect } from '@root/components/sections/custom-designs/hover-effect/';
import { SectionStarCloud } from '@root/components/sections/custom-designs/star-cloud/';
// import { Siphon } from '@root/components/sections/custom-designs/siphon/siphon-app'

export default function Home() {
	return (
		<div>
			<Headers
				title='Embargo'
				desc='Catalogue, Audit, Sanitize, and Restrict 3rd party scripts
from accessing to your most sensitive web page content.'
			/>

			<Hero0
				img={designVisualTagger}
				title='Embargo'
				subtitle={
					<>
						Catalogue, Audit, Sanitize, and Restrict 3rd party
						scripts <br />
						from accessing to your most sensitive web page content.
					</>
				}
			/>

			<MetricsPanel
				metrics={[
					{
						val: '',
						unit: '',
						desc: '',
					},
					/*{
            val: '120',
            unit: '%',
            desc: 'Faster.'
          }, {
            val: '80',
            unit: '%',
            desc: 'Cheaper.'
          }, {
            val: '100',
            unit: '%',
            desc: 'Satisfied.'
          }*/
				]}
			/>

			{/*
    Create Element
    - Tag Element
    
    Match Vendor
    - Assign Priviledges for Resource Access

    Audit Vendor Access

    Monitor Element existance
    Audit sampled values of Elements

*/}

			{[
				{
					img: designGuidelines, // designVisualTagger
					imgAlt: 'Visual Point-and-click',
					lead: 'Visual Point-and-click Creation',
					title: 'Catalogue Elements',
					para: "Build up your web application's inventory of sensitive fields. Embargo will automatically intercept and protect those assets",
					bullets: [
						'Create elements just by clicking.',
						'Assign tags to those elements.',
						'Approve changes by teammates.',
					],
					action: '',
				},
				{
					img: designTags, //'http://jthemes.org/wp/genemy/creative/wp-content/themes/genemy/images/image-01.png',
					imgAlt: 'Welcome to Simplicity',
					lead: 'Welcome to Simplicity',
					title: 'Tag Elements',
					para: 'Let Embargo know what is sensitive, and what is safe.',
					bullets: [
						'Team collaboration with tag approvals and chat',
						'Create custom tags or reuse industry standard tags',
					],
					action: '',
				},
				// {
				//   img: designTags, //'http://jthemes.org/wp/genemy/creative/wp-content/themes/genemy/images/image-01.png',
				//   imgAlt: 'Welcome to Simplicity',
				//   lead: 'Welcome to Simplicity',
				//   title: 'Auto Isolate Vendors',
				//   para: 'By now you know we provide 24/7 towing and roadside assistance services. But did we tell you we respond really fast?',
				//   bullets: [
				//     '',
				//     ''
				//   ],
				//   action: '',
				// },
				// {
				//   img: designHookImg,
				//   imgAlt: 'Welcome to Peace of Mind',
				//   lead: 'Welcome to Peace of Mind',
				//   title: 'Sanitize.',
				//   para: 'Roadside assistance is a surprisingly broad industry to operate within- with a variety of flexible services for everyone.',
				//   bullets: [
				//     'Scrap Car Removal - We\'re proud to be help lead the car cleanup effort in Cayman.',
				//     'Specialty Towing - Everything from motorcycles to tractors we\'ll handle on our flatbed trucks.',
				//     'Private Impound - When someone\'s gotta go, we\'ll professionally deliver our respectful expertise.',
				//     'A helping hand - roadside battery jumper charging, roadside tyre/tire changes.'
				//   ],
				//   action: '',
				// }
			].map((section, i) => (
				<section
					key={i}
					className={styles.section}>
					<div className={styles.secInner}>
						<Image
							className={styles.secImg}
							src={section.img}
							alt={section.imgAlt}
							width={370}
						/>
						<div className={styles.secInfo}>
							<span className={styles.secInfoLead}>
								{section.lead}
							</span>
							<h2 className={styles.secInfoTitle}>
								{section.title}
							</h2>
							<p className={styles.secInfoPara}>{section.para}</p>
							<ul className={styles.secInfoBullets}>
								{section.bullets.map((bullet, i) => (
									<li
										className={styles.secInfoBullet}
										key={i}>
										{bullet}
									</li>
								))}
							</ul>
						</div>
					</div>
				</section>
			))}

			<SectionGlobalInfra />

			<SectionStarCloud
				advantages={
					[
						// [
						//     'Speed is critical to customers — and SEO',
						//     'With a globally backed content delivery network, Embargo delivers to the edge the best performance for your end users, while maintaining best-in-class SEO practices.'
						// ],
						// [
						//     'Continous Stability via Deployment Trains',
						//     'Live at the cutting edge with newly shipped features, or hold back a few versions behind to maximize stability. Preview upcoming deployments on staging and prod on your internal devs before your customers. The choice is yours.'
						// ],
						// [
						//     'Coming Soon — Performance Monitoring',
						//     'Comunicate peace of mind to key stakeholders with upcoming continous production monitoring and benchmarking tools.'
						// ]
					]
				}
			/>

			{/* <SectionGlobe/> */}
			<SectionHoverEffect />
			{/* <Siphon/> */}

			<Footer />
		</div>
	);
}
