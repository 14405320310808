import styles from './star-cloud.module.scss';

// import useWindowSize from "@rooks/use-window-size";
import ParticleImage, {
	Vector,
	// forces, ParticleForce,
} from 'react-particle-image';

// const particleOptions: ParticleOptions = {
//   filter: ({ x, y, image }) => {
//     // Get pixel
//     const pixel = image.get(x, y);
//     // Make a particle for this pixel if blue > 50 (range 0-255)
//     return pixel.b > 50;
//   },
//   color: ({ x, y, image }) => "#61dafb"
// };

// const motionForce = (x: number, y: number): ParticleForce => {
//     return forces.disturbance(x, y, 5);
// };

type SectionStarCloudProps = {
	advantages: [string, string][];
};
export const SectionStarCloud = ({ advantages }: SectionStarCloudProps) => {
	// const { innerWidth, innerHeight } = useWindowSize();
	return (
		<div className={styles.base}>
			<ParticleImage
				className={styles.canvas}
				src={'/static/hands.jpg'}
				width={1400}
				height={400}
				// width={Number(innerWidth) || document.innerWidth}
				// height={Number(innerHeight) || document.innerHeight}
				scale={1.5}
				entropy={10}
				maxParticles={4000}
				// mouseMoveForce={motionForce}
				// touchMoveForce={motionForce}
				backgroundColor='#191D1F'
				particleOptions={{
					filter: ({ x, y, image }) => {
						const pixel = image.get(x, y);
						const sum = pixel.r + pixel.g + pixel.b;
						return sum < 500;
					},
					color: (/*{ x, y, image }*/) => '#492aeb',
					radius: () => Math.random() * 0.6 + 0.15,
					mass: () => 5,
					friction: () => 10,
					initialPosition: ({ canvasDimensions }) => {
						return new Vector(
							canvasDimensions.width / 2,
							canvasDimensions.height / 2,
						);
					},
				}}
			/>

			<div className={styles.container}>
				<div className={styles.advatages}>
					{advantages.map(([title, desc]) => (
						<div
							key={title}
							className={styles.advantage}>
							<div className={styles.title}>{title}</div>
							<div className={styles.desc}>{desc}</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
