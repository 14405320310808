import styles from './metrics-panel.module.scss';

export const MetricsPanel = ({ metrics }) => {
	return (
		<section className={styles.base}>
			<div className={styles.metrics}>
				{!!metrics.length &&
					metrics.map((metric, i) => (
						<figure
							className={styles.metric}
							key={i}>
							<figcaption className={styles.val}>
								{metric.val}
							</figcaption>
							<b className={styles.unit}>{metric.unit}</b>
							<p className={`${styles.desc} rainbow`}>
								{metric.desc}
							</p>
						</figure>
					))}
			</div>
		</section>
	);
};
