import NextHeader from 'next/head';
import { useRouter } from 'next/router';


const PROD_ORIGIN = 'https://embargokit.com';

type HeaderProps = {
	title: string;
	desc: string;
	subgroup?: string;
	statusCode?: number;
};
export const Headers = ({ title, desc, subgroup, statusCode }: HeaderProps) => {
	const router = useRouter();
	const titleLine = `${title} | Embargo${subgroup ? ' ' + subgroup : ''}`;
	const openGraphImg = new URL(`/api/open-graph`, 'https://embargokit.com');
	openGraphImg.searchParams.set('title', title);
	openGraphImg.searchParams.set('pathname', router.asPath);
	const canonicalUrl = new URL(router.asPath, PROD_ORIGIN).href.toLowerCase().replace(/\/$/, ''); // Strip off trailing slash

	return (
		<NextHeader>
			<title>{titleLine}</title>
			<meta
				property='og:title'
				content={titleLine}
			/>

			<meta
				property='og:url'
				content={router.asPath}
			/>
			<meta
				property='og:image'
				content={openGraphImg.href}
			/>

			<link 
				rel="canonical"
				href={canonicalUrl} 
			/>

			<meta
				name='twitter:card'
				content='summary_large_image'
			/>
			<meta
				name='embargo-status'
				content={String(statusCode ?? 200)}
			/>

			{/* <meta name="twitter:site" content="@embargo"/> */}
			{/* <meta property="og:type" content="video.movie" /> */}

			<meta
				name='description'
				content={desc}
			/>
			<meta
				property='og:description'
				content={desc}
			/>
		</NextHeader>
	);
};
