import styles from './hover-effect.module.scss';

const CUBE_SIZE = 4;
const ROWS_COUNT = 80 / CUBE_SIZE;
const COLS_COUNT = 100 / CUBE_SIZE;

export const SectionHoverEffect = () => {
	const title = 'Get Started';
	return (
		<div className={styles.base}>
			<div className={styles.grid}>
				{new Array(ROWS_COUNT).fill(1).map((_, y) => (
					<div
						className={styles.row}
						key={y}>
						{new Array(COLS_COUNT).fill(1).map((_, x) => (
							<div
								className={styles.col}
								key={x}
							/>
						))}
					</div>
				))}
			</div>

			<div className={styles.container}>
				<div className={styles.contentColumn}>
					<h1 className={styles.title}>{title}</h1>

					<h1 className={styles.textbox}>
						Forever <span className='rainbow'>Free to try</span>
					</h1>

					<div className={styles.ctas}>
						{/* <button className={styles.cta}>
                            Watch Demo
                        </button> */}

						{/* <button className={styles.cta}>
                            Join Waitlist
                        </button> */}
					</div>
				</div>
			</div>
		</div>
	);
};
