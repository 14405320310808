import { NavMain } from '@root/components/ui/navbars/nav-main/';
import Image from 'next/image';
import { CountdownTimer } from './countdown-timer';
import styles from './hero0.module.scss';

export const Hero0 = ({ title, subtitle, img }) => {
	return (
		<div className={styles.base}>
			<div className={styles.bgOverlay} />
			<NavMain />

			<div className={styles.container}>
				<div className={styles.twoColumns}>
					<div className={styles.contentColumn}>
						<h1 className={styles.title}>{title}</h1>

						<h1 className={styles.textbox}>
							Protecting Web Apps from{' '}
							<span className='rainbow'>Vendors</span>
						</h1>

						<h2 className={styles.subtitle}>{subtitle}</h2>

						<div className={styles.ctas}>
							{/* <button className={styles.cta}>
                Watch Demo
              </button> */}

							<div>
								<h3 className='lead font-bold text-lg text-gray-400 mt-24'>
									Embargo, Launching Soon
								</h3>

								<CountdownTimer
									date={new Date('2023-09-01T00:00:00.000Z')}
								/>
							</div>

							<form
								className='flex flex-row justify-center items-center'
								action={'/api/email-subscription/upsert'}
								method='POST'>
								<input
									type='email'
									name='email'
									className='bg-slate-800 text-slate-400 inline-block w-48 rounded-md border-slate-800 pr-10 placeholder-slate-300 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
									autoComplete='email'
									placeholder='Enter your email'
									required
								/>

								<input
									type='hidden'
									name='type'
									value='embargo_waiting_list'
								/>

								<button
									type='submit'
									className={styles.animatedBoarderWrapper}>
									<div className={styles.animatedBorder} />
									<div
										className={
											styles.animatedBoarderContent
										}>
										Join Waitlist
									</div>
								</button>
							</form>
						</div>
					</div>

					<div className={styles.imgColumn}>
						{!img ? (
							<span />
						) : (
							<Image
								className={styles.img}
								src={img}
								alt='background img'
								width={768}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
